
settings =
	revealStart: -> "top bottom"
	revealEnd: -> "50% bottom"

import CardSlot from './slot'
import inViewportMixin from 'vue-in-viewport-mixin'
export default
	components: { CardSlot }
	mixins: [ inViewportMixin ]
	props: block: Object

	watch: 'inViewport.now': (inViewport) -> if inViewport then @$nextTick =>
		return unless !@mounted
		@revealWhenInside()

	methods:
		revealWhenInside: ->
			elementsToAnimate = @$refs.wrap.children
			@$gsap.set elementsToAnimate, {opacity: 0}
			@$ScrollTrigger.batch elementsToAnimate, {
				once: true,
				onEnter: ( batch ) => @$gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.1, grid: [1, 3]}, overwrite: true}),
			}
			@mounted = true

